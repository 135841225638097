import React, { Fragment } from "react"
import { StaticImage } from "gatsby-plugin-image"

import SVG from "@src/components/svg/component"

const file = "../../../../../static/uploads/adventurous-life/"

export default {
  default: {
    noPanicHere: {
      heading: "No panic here. For years, we invested in tech. As the world isolated, we engaged.",
      body: (
        <Fragment>
          <p>
            <strong>68% growth</strong> in digital viewing •{" "}
            <strong>1,185,266 people watched</strong> the weekend messages online •{" "}
            <strong>153 video shoots</strong> and <strong>55 songs filmed</strong> at{" "}
            <strong>31 different locations</strong> • <strong>3,000 people</strong> connected to
            groups • <strong>39,866 text messages</strong> sent to subscribers •{" "}
            <strong>2,200 RSVPs</strong> to Leadership Summit • <strong>1,214 RSVPs</strong> to
            Women Leaders Summit
          </p>
        </Fragment>
      ),
      imageVideoShoot: (
        <StaticImage
          alt="Video shoot"
          height={320}
          placeholder="none"
          src={`${file}01--chuck-willie@2x.jpg`}
          width={645}
          layout="fixed"
        />
      ),
      imageZoomWomen: (
        <StaticImage
          alt="Zoom call"
          height={145}
          placeholder="none"
          src={`${file}01--bt-video-shoot@2x.jpg`}
          width={255}
        />
      ),
      imageZoomMen: (
        <StaticImage
          alt="Zoom call"
          height={145}
          placeholder="none"
          src={`${file}01--zoom@2x.png`}
          width={255}
        />
      )
    },
    easter: {
      content: (
        <Fragment>
          <h3>Easter was epic</h3>
          <p>316,000 watched Easter even though it moved online and to TV.</p>
        </Fragment>
      ),
      image: (
        <StaticImage
          alt="Easter"
          height={160}
          placeholder="none"
          src={`${file}01--easter@2x.jpg`}
          width={160}
        />
      )
    },
    bottom_heading:
      "Every view, every like, every listen became an opportunity for people to truly hear God’s word.",
    foundOnSocial: {
      image: (
        <Fragment>
          <StaticImage
            alt="Rob's Story"
            height={265}
            placeholder="none"
            src={`${file}01--robs-story@2x.jpg`}
            width={350}
          />
        </Fragment>
      ),
      heading: "Rob was in a bad spot. Then he found Crossroads on social.",
      body: (
        <Fragment>
          <p>
            Rob went through a painful separation and was rejected by his church; he was alone and
            isolated. Then he found Brian Tome through an Instagram ad. He read the devotional book:{" "}
            <strong>
              <i>MOVE</i>
            </strong>
            , visited Crossroads.net, and then did the 30-day Challenge. Rob was connected with a
            Guide named Vanessa. He joined a small group and now watches every week and began
            giving. Rob said he has found his community, his church, and is closer to God today than
            any point in his 40-year journey as a believer in Jesus.
          </p>
        </Fragment>
      )
    },
    socialStats: [
      {
        body: (
          <Fragment>
            <p>
              <strong>1,026,405</strong> Facebook followers
            </p>
          </Fragment>
        ),
        icon: (
          <Fragment>
            <SVG name="icon-facebook" />
          </Fragment>
        )
      },
      {
        body: (
          <Fragment>
            <p>
              <strong>60,000</strong> YouTube subscribers
            </p>
          </Fragment>
        ),
        icon: (
          <Fragment>
            <SVG name="icon-youtube" />
          </Fragment>
        )
      },
      {
        body: (
          <Fragment>
            <p>
              <strong>171</strong> New articles offering another perspective
              <SVG name="arrow-down-alt" />
            </p>
          </Fragment>
        ),
        icon: (
          <Fragment>
            <SVG name="icon-browser" />
          </Fragment>
        )
      }
    ],
    whatIs: {
      body: (
        <Fragment>
          <p>
            <a
              href="https://www.crossroads.net/media/articles/what-is-spiritual-warfare-and-how-do-i-do-it"
              target="_blank"
              rel="noreferrer"
            >
              <em>What is Spiritual Warfare, and How Do I Do It?</em>
            </a>{" "}
            has been read <strong>70,702 times</strong>.
          </p>
        </Fragment>
      ),
      image: (
        <Fragment>
          <StaticImage
            alt="Spiritual warfare website"
            height={287}
            placeholder="none"
            src={`${file}01--spiritual-warfare--website@2x.jpg`}
            width={255}
          />
        </Fragment>
      )
    },
    music: {
      image: (
        <StaticImage
          alt="Crossroads Band"
          height={232}
          placeholder="none"
          src={`${file}01--crds-band@2x.jpg`}
          width={635}
        />
      ),
      musicStats: [
        {
          body: (
            <Fragment>
              <p>
                <strong>6</strong> Albums published by Crossroads Music
              </p>
            </Fragment>
          ),
          icon: (
            <Fragment>
              <SVG name="icon-vinyl" />
            </Fragment>
          )
        },
        {
          body: (
            <Fragment>
              <p>
                <strong>154% </strong>
                Listener increase on Crossroads Music’s Spotify channel
              </p>
            </Fragment>
          ),
          icon: (
            <Fragment>
              <SVG name="icon-spotify" />
            </Fragment>
          )
        }
      ]
    }
  }
}
