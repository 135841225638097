import React from "react"
import Component from "./component"
import fixtures from "./fixtures"

const NoPanic = () => {
  return <Component {...fixtures.default} />
}

export default NoPanic

export { Component as component, fixtures }
