/* eslint-disable react/prop-types */

import React from "react"

import MediaQuery from "react-responsive"
import Animation from "@src/components/animation"

import BackgroundImage from "@src/components/background-image/static"

import styles from "./styles.module.scss"

const NoPanic = ({
  bottom_heading,
  easter,
  foundOnSocial,
  music,
  noPanicHere,
  socialStats,
  whatIs
}) => {
  const MusicSection = () => (
    <div className={styles.music}>
      <Animation revealFrom="top" contentClassName={styles.band}>
        {music.image}
      </Animation>

      <ul className={styles.music_stats}>
        {music.musicStats.map((stat, index) => {
          return (
            <Animation fade="up" tag="li" key={index}>
              {stat.icon}
              {stat.body}
            </Animation>
          )
        })}
      </ul>
    </div>
  )

  return (
    <BackgroundImage
      className={styles.no_panic_background}
      image="backgrounds/topo-pattern-dark.png"
    >
      <div className={styles.container}>
        <div className={styles.no_panic_here_content}>
          <div>
            <Animation fade="right" tag="h2">
              {noPanicHere.heading}
            </Animation>

            <Animation revealFrom="right" partialVisibility contentClassName={styles.video_shoot}>
              {noPanicHere.imageVideoShoot}
            </Animation>
          </div>
          <div>
            <Animation fade="left" contentClassName={styles.growth_in_digital}>
              {noPanicHere.body}
            </Animation>

            <div className={styles.zoom_calls}>
              <Animation fade="right">{noPanicHere.imageZoomWomen}</Animation>
              <Animation fade="right">{noPanicHere.imageZoomMen}</Animation>
            </div>

            <div className={styles.easter_container}>
              <Animation revealFrom="top">{easter.image}</Animation>
              <Animation fade="right">{easter.content}</Animation>
            </div>
          </div>
        </div>
        <div className={styles.every_view}>
          <Animation fade="left" tag="h2">
            {bottom_heading}
          </Animation>

          <div className={styles.every_view_content}>
            <div className={styles.left}>
              <Animation revealFrom="left" partialVisibility>
                {foundOnSocial.image}
              </Animation>
              <Animation fade="left" tag="h3">
                {foundOnSocial.heading}
              </Animation>
              <Animation fade="in" partialVisibility delay={8}>
                {foundOnSocial.body}
              </Animation>
            </div>
            <div className={styles.right}>
              <div className={styles.top}>
                <ul className={styles.social_stats}>
                  {socialStats.map((stat, index) => {
                    return (
                      <Animation fade="up" tag="li" key={index}>
                        {stat.icon}
                        <div>{stat.body}</div>
                      </Animation>
                    )
                  })}
                </ul>

                <Animation partialVisibility contentClassName={styles.what_is} fade="up">
                  {whatIs.image}
                  {whatIs.body}
                </Animation>
              </div>
              <MediaQuery minWidth={960}>
                <MusicSection />
              </MediaQuery>
            </div>
            <MediaQuery maxWidth={959}>
              <MusicSection />
            </MediaQuery>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default NoPanic
